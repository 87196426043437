import Swiper from 'swiper';
import '@fancyapps/fancybox';

export function catalogitemSlider() {

    initFancybox();

    const $g = $('.catalog-item-slider');

    if(!$g.length) return;

    const galleryTop = new Swiper('.catalog-item-slider', {
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        loop: true,
        loopedSlides: $g.find('.swiper-slide').length,
    });
    const galleryThumbs = new Swiper('.catalog-item-slider-thumbs', {
        spaceBetween: 10,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        loop: true,
        loopedSlides: $g.find('.swiper-slide').length,
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 2,
            },
            1199: {
                slidesPerView: 3,
            }
        }
    });
    galleryTop.controller.control = galleryThumbs;
    galleryThumbs.controller.control = galleryTop;
}

function initFancybox() {

    const $g = $('[data-fancybox="gallery"]');

    if(!$g.length) return;

    $g.fancybox({
        infobar: false,
        smallBtn: true,
        backFocus: false,
        btnTpl: {
            smallBtn:
                '<button type="button" data-fancybox-close class="close-gallery">' +
                '<i class="if if-close"></i>' +
                '</button>',
            arrowLeft:
                '<button data-fancybox-prev class="gallery-prev">' +
                '<i class="if if-arrow-left"></i>' +
                '</button>',

            arrowRight:
                '<button data-fancybox-next class="gallery-next">' +
                '<i class="if if-arrow-right"></i>' +
                '</button>',
        }
    });
}
