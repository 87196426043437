export function footerInit() {
    const $v = $('.info-block-mobile');
    const $it = $v.find('.title');


    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.ul-mobile').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.title.transform-icon');

            $('.ul-mobile').slideUp(300);
            $ti.removeClass('transform-icon');


            $c.addClass('transform-icon');
            $c.next('.ul-mobile').slideDown(300);
        }
    });
}