import Swiper from 'swiper';
import {addZero} from "../modules/functions";

export function serviceListSlider() {

    const $s = $('.service-list-slider');

    if(!$s.length) return;

    const slider = new Swiper('.service-list-slider', {
        navigation: {
            nextEl: '.service-list-slider-next',
            prevEl: '.service-list-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        }
    });
}
