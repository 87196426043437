$(document).ready(function() {
  $('form.feedback-form button').on('click', function(event) {
    let form = $(this).parent();
    let filledInputs = form.find('input.required').filter(function() {
                        return $.trim($(this).val()).length > 0
                      }).length == form.find('input.required').length;
    let isCheched = form.parent().find('input:checkbox')[0].checked

    if (!(filledInputs && isCheched)) {
      event.preventDefault();

      form.find($('span.label')).each(function() {
        if (!$(this.previousSibling).hasClass('required')) return;
        let inputValue = this.previousSibling.value;
        let labelElements = $(this).parent().children();
        inputValue ? labelElements.removeClass('empty') : labelElements.addClass('empty');
      });

      let checkbox = form.parent().find('.checkbox');
      isCheched ? checkbox.removeClass('empty') : checkbox.addClass('empty');
    }
  })
});
