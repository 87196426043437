export function headerInit() {
  const $v = $(".header-navigation-mobile");
  const $it = $v.find(".header-title");
  thirdMenu();

  $(".burger-button").on("click", function () {
    if (!$v.is(".showed")) {
      $v.stop(true, true).slideDown(300);
      $v.addClass("showed");
    } else {
      $v.slideUp(300);
      $v.removeClass("showed");
    }
  });

  $it.on("click", function () {
    const $c = $(this);

    if ($c.is(".transform-icon")) {
      $c.removeClass("transform-icon");
      $(".ul-header-mobile").stop(true, true).slideUp(300);
    } else {
      const $ti = $v.find(".header-title.transform-icon");

      $(".ul-header-mobile").slideUp(300);
      $ti.removeClass("transform-icon");

      $c.addClass("transform-icon");
      $c.next(".ul-header-mobile").slideDown(300);
    }
  });

  $(".input-search").on("focus", (e) => {
    const $t = $(e.currentTarget);
    $t.parents(".form-header").addClass("form-focus");
    $(".topmenu-name").addClass("topmenu-focus");
  });
  $(".input-search").on("blur", (e) => {
    const $t = $(e.currentTarget);
    $t.parents(".form-header").removeClass("form-focus");
    $(".topmenu-name").removeClass("topmenu-focus");
  });

  const $parentMenu = $(".parent-menu");
  let timeout;
  $parentMenu.on("mouseenter", (e) => {
    const $t = $(e.currentTarget);
    const $submenu = $t.find(">.submenu");
    $submenu.removeClass("hide");
    $submenu.addClass("show");
    clearTimeout(timeout);
  });
  $parentMenu.on("mouseleave", (e) => {
    const $t = $(e.currentTarget);
    const $submenu = $t.find(">.submenu");
    $submenu.addClass("hide");
    timeout = setTimeout(() => {
      $submenu.removeClass("hide show");
    }, 400);
  });
}

$(document).ready(function () {
  $(window).scroll(function () {
    const scroll = $(window).scrollTop();

    if (scroll >= 137) {
      $(".site-header").addClass("fixedHeader");
      $(".site-header-mobile").addClass("fixedHeaderMobile");
      $(".header-navigation").addClass("navigation-none");
      $(".phone-header").addClass("phone-scroll");
      $(".image-normal").addClass("logo-header-scroll");
      $(".image-scroll").addClass("logo-header-scroll-image");
      $(".header-top").addClass("site-header-scroll");
      $(".button-header").addClass("button-scroll");
      $(".slogan-header").addClass("slogan-scroll");
      $(".header-top-main").addClass("header-top-main-scroll");
      $(".header-menu-mobile").addClass("header-menu-mobile-scroll");
      $(".controls-header").addClass("controls-header-scroll");
      $(".button-header-small").addClass("button-header-small-scroll");
      $(".form-mobile").addClass("form-scroll");
      $(".header-top-main-mobile").addClass("header-top-main-mobile-scroll");
      $(".header-top-main-mobile").removeClass("header-top-main-scroll");
      $(".burger-button-mobile").addClass("burger-button-mobile-scroll");
    } else {
      $(".site-header").removeClass("fixedHeader");
      $(".site-header-mobile").removeClass("fixedHeaderMobile");
      $(".header-navigation").removeClass("navigation-none");
      $(".phone-header").removeClass("phone-scroll");
      $(".image-normal").removeClass("logo-header-scroll");
      $(".image-scroll").removeClass("logo-header-scroll-image");
      $(".header-top").removeClass("site-header-scroll");
      $(".button-header").removeClass("button-scroll");
      $(".slogan-header").removeClass("slogan-scroll");
      $(".header-top-main").removeClass("header-top-main-scroll");
      $(".header-menu-mobile").removeClass("header-menu-mobile-scroll");
      $(".controls-header").removeClass("controls-header-scroll");
      $(".button-header-small").removeClass("button-header-small-scroll");
      $(".form-mobile").removeClass("form-scroll");
      $(".header-top-main-mobile").removeClass("header-top-main-mobile-scroll");
      $(".burger-button-mobile").removeClass("burger-button-mobile-scroll");
    }
  });
});

function thirdMenu() {
  const $toggle = $(".third-menu-toggle");
  $toggle.on("click", (e) => {
    const $t = $(e.currentTarget);
    const $parent = $t.parent(".third-menu");
    if (!$parent.is(".show")) {
      hideShowed();
    }
    $parent.toggleClass("show");
    $t.next(".third-menu-navigation").slideToggle(300);
  });
}

function hideShowed() {
  const $item = $(".third-menu.show");
  $item.removeClass("show");
  $item.find(".third-menu-navigation").slideUp(300);
}
