export function aboutbenefitsInit() {
    const $v = $('.about-benefits-content');
    const $it = $v.find('.item-title');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-bottom')) {
            $c.removeClass('transform-bottom');
            $('.item .content').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.item-title.transform-bottom');

            $('.item .content').slideUp(300);
            $ti.removeClass('transform-bottom');


            $c.addClass('transform-bottom');
            $c.next('.content').slideDown(300);
        }
    });
}