export function parametersInit() {
    const $v = $('.filters');
    const $it = $v.find('.filters-name');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.parameters').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.filters-name.transform-icon');

            $('.parameters').slideUp(300);
            $ti.removeClass('transform-icon');


            $c.addClass('transform-icon');
            $('.parameters').slideDown(300);
        }
    });
}