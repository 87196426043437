import Inputmask from "inputmask";

export function formControl() {
    const $fc = $(".form-control");

    if (!$fc.length) return;

    $fc.each(function() {
        const $input = $(this);
        if ($input.attr('type') === 'tel') {
            Inputmask({
                mask: "+7 (999) 999-9999",
                showMaskOnHover: false
            }).mask($input);
        }
    });

    $fc.blur(function() {
        const $t = $(this);

        if ($t.next('.label').length) {
            if ($t.val()) {
                $t.next('.label').addClass("filled");
            } else {
                $t.next('.label').removeClass("filled");
            }
        }
    });
}
