export function galleryInit() {

    const $l = $('.load-more-gallery');
    const $h = $('.hide-more-gallery');

    if(!$l.length) return;

    // $l.on('click', (e) => {
    //     e.preventDefault();

    //     $.ajax({
    //         url: '/assets/ajax/gallery.html',
    //         success: data => {
    //             $('.gallery-list').append(data);
    //         }
    //     })
    // });

    $l.on('click', (e) => {
        let hiddenCategories = $('.gallery-item.loaded.invisible')
        let categoriesToShow = hiddenCategories.slice(0, 4);
        categoriesToShow.removeClass('invisible');
        if ($('.gallery-item.loaded.invisible').length == 0) {
            $l.addClass('invisible');
            $h.removeClass('invisible');
        };
    });

    $h.on('click', (e) => {
        $('.gallery-item.loaded').addClass('invisible');
        $h.addClass('invisible');
        $l.removeClass('invisible');
    });
}


