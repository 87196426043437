import Swiper from 'swiper';

export function aboutclientsSlider() {
    const $s = $('.about-clients-slider');

    if(!$s.length) return;

    const slider = new Swiper('.about-clients-slider', {
        navigation: {
            nextEl: '.about-clients-slider-next',
            prevEl: '.about-clients-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            650: {
                slidesPerView: 2,
            }
        }
    });

}