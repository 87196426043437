$(document).ready(function () {
  $('#more-products').on('click', function() {
    $(this).data('page', ($(this).data("page") + 1));
    let urlData = 'path=' + $(this).data('path') + '&page=' + $(this).data('page');
    if ($(this).data('params')) urlData += '&filter=' + JSON.stringify($(this).data('params'));

    $.ajax({
      url: "/catalog/load_products",
      data: urlData,
      error: function() {
        $(this).data('page', ($(this).data("page") - 1));
      }
    });
  })
});
