import currency from 'currency.js/dist/currency.js';

function getPercent(advance, term) {
  if (term < 24) {
    if (advance < 20) return 7;
    if (advance >= 20 && advance < 30) return 6.5;
    if (advance >= 30) return 6;
  } else if (term >= 24 && term < 36) {
    if (advance < 30) return 7;
    if (advance >= 30) return 6;
  } else if (term >= 36 && term < 48) {
    if (advance < 10) return 9;
    if (advance >= 10 && advance < 30) return 8.5;
    if (advance >= 30) return 8;
  } else {
    if (advance < 10) return 9.5;
    if (advance >= 10 && advance < 30) return 8;
    if (advance >= 30) return 7;
  }
}

$(document).ready(function () {
  let lastPrice;

  let price = +$("#parameter-price").find($(".noUi-tooltip")).text().replace(/ /g, '');
  let advance = +$("#parameter-advance").find($(".noUi-tooltip")).text();
  let term = +$("#parameter-deadline").find($(".noUi-tooltip")).text();
  let percent = getPercent(advance, term);
  let payment =
    (((price * (100 - advance)) / 100) * (1 + (percent * term) / 12 / 100)) /
    term;
  $(".calculation-price-summary").text(currency(payment, { separator: ' ', precision: 0, symbol: 'руб.', pattern: `# !` }).format());
  $(".month").text(term);

  $("body").on('DOMSubtreeModified', "#parameter-price .noUi-tooltip", function() {
    let price = +$("#parameter-price").find($(".noUi-tooltip")).text();
    let advance = +$("#parameter-advance").find($(".noUi-tooltip")).text();
    let term = +$("#parameter-deadline").find($(".noUi-tooltip")).text();

    if (price == 0) {
      $(".calculation-price-summary").text(currency(0, { separator: ' ', precision: 0, symbol: 'руб.', pattern: `# !` }).format());
      return;
    };

    if ((lastPrice == price) || isNaN(price)) return;
    lastPrice = price;

    let percent = getPercent(advance, term);
    let payment =
      (((price * 1000 * (100 - advance)) / 100) * (1 + (percent * term) / 12 / 100)) /
      term;
    let textPrice = currency((price + '000'), { separator: ' ', precision: 0, symbol: '' }).format();
    $("#parameter-price").find($(".noUi-tooltip")).text(textPrice)
    $(".calculation-price-summary").text(currency(payment, { separator: ' ', precision: 0, symbol: 'руб.', pattern: `# !` }).format());
    $(".month").text(term);
  });

  $("body").on('DOMSubtreeModified', "#parameter-advance .noUi-tooltip, #parameter-deadline .noUi-tooltip", function() {
    let price = +$("#parameter-price").find($(".noUi-tooltip")).text().replace(/ /g, '');
    let advance = +$("#parameter-advance").find($(".noUi-tooltip")).text();
    let term = +$("#parameter-deadline").find($(".noUi-tooltip")).text();
    let percent = getPercent(advance, term);
    let payment =
      (((price * (100 - advance)) / 100) * (1 + (percent * term) / 12 / 100)) /
      term;
    $(".calculation-price-summary").text(currency(payment, { separator: ' ', precision: 0, symbol: 'руб.', pattern: `# !` }).format());
    $(".month").text(term);
  });

  $("#leasing_form").on("click", function (e) {
    e.preventDefault();
    showPopup($('#call-leasing'));
  });
});
