import Swiper from 'swiper';
import {addZero} from "../modules/functions";
import '@fancyapps/fancybox';

export function subcategoryinfoSlider() {

    initFancybox();

    const $s = $('.subcategory-info-slider');

    if(!$s.length) return;

    const slider = new Swiper('.subcategory-info-slider', {
        navigation: {
            nextEl: '.subcategory-info-slider-next',
            prevEl: '.subcategory-info-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        mousewheel: true
    });
}

function initFancybox() {

    const $g = $('[data-fancybox="gallery"]');

    if(!$g.length) return;

    $g.fancybox({
        infobar: false,
        smallBtn: true,
        backFocus: false,
        btnTpl: {
            smallBtn:
                '<button type="button" data-fancybox-close class="close-gallery">' +
                '<i class="if if-close"></i>' +
                '</button>',
            arrowLeft:
                '<button data-fancybox-prev class="gallery-prev">' +
                '<i class="if if-arrow-left"></i>' +
                '</button>',

            arrowRight:
                '<button data-fancybox-next class="gallery-next">' +
                '<i class="if if-arrow-right"></i>' +
                '</button>',
        }
    });
}