import {
  burgerInit,
  fancyboxOpen,
  formControl,
  galleryInit,
  homePartnersSlider,
  betterSlider,
  bestSlider,
  initCounters,
  initPopup,
  navigationInit,
  newsSlider,
  reviewsSlider,
  mainSlider,
  categorycatalogSlider,
  newsitemSlider,
  subcategoryinfoSlider,
  aboutmediaSlider,
  leasingPartnersSlider,
  aboutclientsSlider,
  aboutpartnersSlider,
  productionSlider,
  catalogitemSlider,
  recommendSlider,
  serviceListSlider,
  animate,
  catalogTabs,
} from "./components";
import { filtersInit } from "./components/filters";
import { accordionInit } from "./components/accordion";
import { aboutbenefitsInit } from "./components/about-benefits";
import { parametersInit } from "./components/parameters";
import { loadMap } from "./modules/contact-map";
import { sectionTab } from "./components/section-tab";
import { footerInit } from "./components/footer";
import { headerInit } from "./components/header";
import { servicelistInit } from "./components/service-list";
import { nouiSlider } from "./components/installments";

$(document).ready(init);

function init() {
  burgerInit();
  navigationInit();
  betterSlider();
  homePartnersSlider();
  initCounters();
  galleryInit();
  newsSlider();
  formControl();
  fancyboxOpen();
  reviewsSlider();
  bestSlider();
  mainSlider();
  categorycatalogSlider();
  newsitemSlider();
  subcategoryinfoSlider();
  initPopup();
  filtersInit();
  parametersInit();
  loadMap();
  aboutbenefitsInit();
  aboutmediaSlider();
  leasingPartnersSlider();
  aboutclientsSlider();
  aboutpartnersSlider();
  productionSlider();
  catalogitemSlider();
  serviceListSlider();
  sectionTab();
  recommendSlider();
  accordionInit();
  footerInit();
  headerInit();
  servicelistInit();
  nouiSlider();
  animate();
  catalogTabs();
}
