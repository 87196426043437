import Swiper from 'swiper';
import {addZero} from "../modules/functions";

export function aboutmediaSlider() {

    const $s = $('.about-media-slider');

    if(!$s.length) return;

    const slider = new Swiper('.about-media-slider', {
        navigation: {
            nextEl: '.about-media-slider-next',
            prevEl: '.about-media-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        mousewheel: false
    });
}