export function catalogTabs() {
    const $tabs = $('.catalog-tabs');
    if(!$tabs || $tabs.length === 0) return;

    const $tabsNavigationButton = $('.catalog-tabs-navigation-button');
    const $tabsPane = $('.catalog-tabs-pane');

    $tabsNavigationButton.eq(0).addClass('active');
    $tabsPane.eq(0).addClass('active');

    $tabsNavigationButton.on('click', (e) => {
        e.preventDefault();
        const $t = $(e.currentTarget);
        if($t.is('.active')) return;

        $tabsNavigationButton.removeClass('active');
        $tabsPane.removeClass('active');

        $t.addClass('active');
        $('#' + $t.data('tab')).addClass('active');
    });
}
