import {mapStyles} from '../components';

export function loadMap() {
    let scriptPath = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAnocJLMn281z_ZaHCUk2DBnqnp8YK2Du8";
    let script = document.createElement('script');
    script.setAttribute("type","text/javascript");
    script.src = scriptPath;
    document.body.append(script);
    script.onload = () => {
        if ($('#contact-map').length) contactMap('#contact-map');
        if ($('#contact-map-mobile').length) contactMap('#contact-map-mobile');
        if ($('#contact-map-service').length) contactMapService();
    }
}

function contactMap(elementId) {
    const $mapElements = $(elementId);

    const pos = new google.maps.LatLng(55.822519, 37.443335);
    const map = new google.maps.Map(
        $mapElements[0],
        {
            zoom: 17,
            center: pos,
            styles: mapStyles
        },
    );

    const marker = new google.maps.Marker({
        position: pos,
        icon: $mapElements.data('pin'),
        map: map,
        anchorPoint: new google.maps.Point(-43, -33)
    });
}

function contactMapService() {
    const $mapElements = $('#contact-map-service');

    const pos = new google.maps.LatLng(57.06450967683184, 60.36866242377609);
    const map = new google.maps.Map(
        document.getElementById('contact-map-service'),
        {
            zoom: 4.25,
            center: pos,
            styles: mapStyles
        },
    );

    const markers = [];
    $("#service-contacts tbody tr").map(function (i) {
        let coors = $(this).data("coordinate").split(', ');
        let marker = {};
        marker.id = $(this).data("windowid");
        marker.lat = +coors[0];
        marker.lng = +coors[1];
        marker.address = $(this).find("td:eq(1)").text();
        markers.push(marker);
    });
    const mapMarkers = {};
    const infoWindows = {};

    markers.map((x) => {
        mapMarkers['id' + x.id] = new google.maps.Marker({
            position: new google.maps.LatLng(x.lat, x.lng),
            icon: $mapElements.data('pin'),
            map: map,
            anchorPoint: new google.maps.Point(-43, -33)
        });

        const popupContent =
            '<div class="gm-style">' +
            '<div class="content">' +
            '<div class="infobox__address">' +
            x.address +
            "</div>" +
            '<a href="#service" class="infobox__button button open-popup">' +
            "Записаться на сервис</a> " +
            '<a href="#spareparts" class="infobox__button button open-popup">' +
            "Заказать запчасти</a> " +
            "</div>" +
            "</div>";

        infoWindows['id' + x.id] = new google.maps.InfoWindow({
            content: popupContent,
        });

        mapMarkers['id' + x.id].addListener("click", () => {
            infoWindows['id' + x.id].open({
                anchor: mapMarkers['id' + x.id],
                map,
                shouldFocus: false,
            });
        });

        $('.service-contacts-table__icon').on('click', (e) => {
            const id = $(e.currentTarget).data('windowid');
            infoWindows['id' + id].open({
                anchor: mapMarkers['id' + id],
                map,
                shouldFocus: false,
            });

            $('html,body').stop(true, false).animate({
                scrollTop: $("#contact-map-service").offset().top
            }, 2000);
        });
    });
}
