import Swiper from 'swiper';
import {addZero} from "../modules/functions";

export function bestSlider() {
    const $s = $('.best-slider');

    if(!$s.length) return;

    const slider = new Swiper('.best-slider', {
        navigation: {
            nextEl: '.best-slider-next',
            prevEl: '.best-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
                mousewheel: false
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 20,
                mousewheel: false
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 25,
                mousewheel: true
            },
            1700: {
                slidesPerView: 4,
                spaceBetween: 30,
                mousewheel: true
            }
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        }
    });

}
