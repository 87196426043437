import Swiper from 'swiper';

export function leasingPartnersSlider() {
    const $s = $('.leasing-partners-slider');

    if(!$s.length) return;

    const slider = new Swiper('.leasing-partners-slider', {
        navigation: {
            nextEl: '.leasing-partners-slider-next',
            prevEl: '.leasing-partners-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            650: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
            1700: {
                slidesPerView: 4,
            }
        }
    });

}