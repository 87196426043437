import Swiper from 'swiper';

export function mainSlider() {
    const $s = $('.main-slider');

    if(!$s.length) return;

    const slider = new Swiper('.main-slider', {
        navigation: {
            nextEl: '.main-slider-next',
            prevEl: '.main-slider-prev',
        },
        slidesPerView: 1,
        pagination: {
            el: '.main-slider-pagination',
            type: 'bullets',
            clickable: true,
        },
        loop: true,
    });

}
