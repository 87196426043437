$(document).ready(function () {
  $(".parameters li").on("click", function () {
    let id = $(this).data("id");
    let index = $(this).data("index");
    let select = $(`#filter_${id}`);

    if ($(this).hasClass("all")) {
      $(this).siblings().removeClass("active");
      $(this).addClass("active");
      $(select[0].options).each(function () {
        this.selected = false;
      });
    } else if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      select[0].options[index].selected = false;
    } else {
      $(this).addClass("active");
      $(this).siblings(".all").removeClass("active");
      select[0].options[index].selected = true;
    }

    let data = { filter: {} };
    $("form.form-filter select").each(function () {
      data["filter"][$(this).data("id")] = $(this).val();
    });
    $.ajax({
      url: `${$("form.form-filter").data("url")}/products_count`,
      data: data,
    });

    $('.form-filter').attr('action', window.location.pathname + '?' + jQuery.param(data))
  });

  $("button.reset").on("click", function (event) {
    event.preventDefault();
    $(this)
      .parent()
      .find("select")
      .each(function () {
        $($(this)[0].options).each(function () {
          this.selected = false;
        });
      });
    $(".parameters li").removeClass("active");
    $.ajax({
      url: `${$("form.form-filter").data("url")}/products_count`,
    });
    history.pushState({}, "", window.location.pathname);
  });
});
