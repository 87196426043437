import * as noUiSlider from 'nouislider';
import currency from 'currency.js/dist/currency.js';

export function nouiSlider() {

    const parameterprice = document.getElementById('parameter-price');
    let price = ($('.price-info').length) ? Number($('.price-info').data().price) : 0

    if(parameterprice) {
        noUiSlider.create(parameterprice, {
            start: price,
            direction: 'ltr',
            connect: [true, false],
            tooltips: [true],
            step: 50,
            range: {
                'min': 0,
                'max': 50000
            },
            format: {
                from: function(value) {
                    return parseInt(value);
                },
                to: function(value) {
                    return parseInt(value);
                }
            }
        });

        if (price > 0) {
            let textPrice = currency((price + '000'), { separator: ' ', precision: 0, symbol: '' }).format();
            $("#parameter-price").find($(".noUi-tooltip")).text(textPrice)
        }
    }


    const parameteradvance = document.getElementById('parameter-advance');

    if(parameteradvance) {
        let start = 10;
        if(price > 0) {
            start = 30
        }

        noUiSlider.create(parameteradvance, {
            start: start,
            direction: 'ltr',
            connect: [true, false],
            tooltips: [true],
            range: {
                'min': 10,
                'max': 100
            },
            format: {
                from: function(value) {
                    return parseInt(value);
                },
                to: function(value) {
                    return parseInt(value);
                }
            }
        });
    }


    const parameterdeadline = document.getElementById('parameter-deadline');

    if(parameterdeadline) {
        let start = 6;
        if(price > 0) {
            start = 36
        }
        noUiSlider.create(parameterdeadline, {
            start: start,
            direction: 'ltr',
            connect: [true, false],
            tooltips: [true],
            range: {
                'min': 6,
                'max': 60
            },
            format: {
                from: function(value) {
                    return parseInt(value);
                },
                to: function(value) {
                    return parseInt(value);
                }
            }
        });
    }
}
