export * from './burger';
export * from './navigation';
export * from './better-slider';
export * from './counters';
export * from './gallery';
export * from './news-slider';
export * from './form-control';
export * from './map-style';
export * from './fancybox-open';
export * from './partners-slider';
export * from './reviews-slider';
export * from './best-slider';
export * from './main-slider';
export * from './category-catalog-slider';
export * from './news-item-slider';
export * from './subcategory-info-slider';
export * from './filters';
export * from './parameters';
export * from './popup';
export * from './about-benefits';
export * from './about-media-slider';
export * from './leasing-partners-slider';
export * from './about-clients-slider';
export * from './about-partners-slider';
export * from './production-slider';
export * from './catalog-item-slider';
export * from './section-tab';
export * from './recommend-slider';
export * from './accordion';
export * from './footer';
export * from './header';
export * from './service-list-slider';
export * from './service-list';
export * from './installments';
export * from './animate';
export * from './catalog-tabs'

const inputIds = [
    "name_callback", "email_callback", "phone_callback",
    "mail_callback", "message_callback", "name_leasing",
    "phone_leasing", "mail_leasing", "message_leasing",
    "name_vopros", "phone_vopros", "name_zvonok",
    "phone_zvonok", "message_zvonok", "name_zakaz",
    "phone_zakaz", "mail_zakaz", "comment_zakaz",
    "name_service", "phone_service", "mail_service", "message_service",
    "name_repair", "phone_repair", "mail_repair", "message_repair",
    "name_doc", "phone_doc", "mail_doc", "message_doc"
];

inputIds.forEach(function(id) {
    const inputField = document.getElementById(id);
    if (inputField) {
        inputField.addEventListener("change", function() {
            ym(10957531, 'reachGoal', id);
        });
    }
});
