export function accordionInit() {
    const $v = $('.section-tabs-mobile');
    const $it = $v.find('.section-tabs-mobile-menu');

    $it.on('click', function () {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.section-tab-pane-mobile').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.section-tabs-mobile-menu.transform-icon');

            if ($ti.length) {
                $('html, body').stop(true, false).animate({
                    scrollTop: $ti.offset().top - 50
                }, 100);
            }

            $('.section-tab-pane-mobile').slideUp(300);
            $ti.removeClass('transform-icon');


            $c.addClass('transform-icon');
            $c.next('.section-tab-pane-mobile').slideDown(300);
        }
    });
}