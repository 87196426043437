import Swiper from 'swiper';
import {addZero} from '../modules/functions';

export function betterSlider() {
    const $s = $('.better-slider');

    if(!$s.length) return;

    const slider = new Swiper('.better-slider', {
        cssMode: true,
        navigation: {
            nextEl: '.better-slider-next',
            prevEl: '.better-slider-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            650: {
                slidesPerView: 2,
            },
            1280: {
                slidesPerView: 3,
            },
            1700: {
                slidesPerView: 4,
            }
        },
        mousewheel: true
    });
}