import Swiper from 'swiper';

export function reviewsSlider() {
    const $s = $('.reviews-slider');

    if(!$s.length) return;

    const slider = new Swiper('.reviews-slider', {
        navigation: {
            nextEl: '.reviews-slider-next',
            prevEl: '.reviews-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            650: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
            1700: {
                slidesPerView: 3,
            }
        }
    });

}