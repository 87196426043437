import Swiper from 'swiper';

export function aboutpartnersSlider() {
    const $s = $('.about-partners-slider');

    if(!$s.length) return;

    const slider = new Swiper('.about-partners-slider', {
        navigation: {
            nextEl: '.about-partners-slider-next',
            prevEl: '.about-partners-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            650: {
                slidesPerView: 2,
            }
        }
    });

}