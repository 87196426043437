$(document).ready(function() {
  $('#more-news').on('click', function() {
    $(this).data('page', ($(this).data("page") + 1));

    $.ajax({
      url: $(this).data('url'),
      data: $(this).data(),
      error: function() {
        $(this).data('page', ($(this).data("page") - 1));
      }
    });
  })
})
