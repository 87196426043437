export function sectionTab() {
    if (!$('.section-tabs-menu.active').length) return
    let elementId = $('.section-tabs-menu.active')[0].hash
    $(elementId).show();

    $('.section-tabs a').click(function(){
        if( $(this).attr('class') == 'active' ){
            return false;
        }

        var link = $(this).attr('href');
        var prevActive = $('a.active').attr('href');
        $('a.active').removeClass('active');
        $(this).addClass('active');

        $(prevActive).fadeOut(300, function(){
            $(link).fadeIn(300);


        });
        return false;
    });
}