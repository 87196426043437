export function filtersInit() {
    const $v = $('.parameters');
    const $it = $v.find('.parameters-content-name');

    if (!$v.length) return;

    $('.parameters-content ul li.active').slideDown();

    $it.on('click', function (event) {
        const $c = $(this);


        if($c.is('.transform-icon')) {
            $c.removeClass('transform-icon');
            $('.parameters-content ul li:not(.active)').stop(true, true).slideUp(300);

        } else {

            const $ti = $v.find('.parameters-content-name.transform-icon');

            $('.parameters-content ul li:not(.active)').slideUp(300);
            $ti.removeClass('transform-icon');


            $c.addClass('transform-icon');
            $c.next('ul').children().slideDown(300);
        }

        event.stopPropagation();
    });

    $('.parameters-content ul').on('click', function() {
        event.stopPropagation();
    });

    $(document).on('click', function() {
        $it.removeClass('transform-icon');
        $('.parameters-content ul li:not(.active)').slideUp(300);
    });
}