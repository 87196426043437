export function navigationInit() {
    const $hn = $('.header-navigation');
    const $parent = $hn.find('.parent');

    $parent.on('click', function () {
        const $t = $(this);

        if($t.is('.active')) {
            $t.removeClass('active');
            $t.next().stop(true, true).slideUp(300);
        } else {
            const $active = $hn.find('.parent.active');

            $active.next().slideUp(300);
            $active.removeClass('active');


            $t.addClass('active');
            $t.next().slideDown(300);
        }
    });
}
