export function animate() {
    $('.button-leasing').click(function(){
        $('html, body').stop(true, false).animate({
            scrollTop: $("#leasing").offset().top - 200
        }, 1000);
    });

    $(".button-catalog").click(function(event){
        event.preventDefault();
        event.stopPropagation();

        if ($(this).hasClass('active') || $(window).width() > 1199) {
            $('html, body').stop(true, false).animate({
                scrollTop: $(".category-description").offset().top - 200
            }, 1000);
        } else {
            $(this).addClass('active');
            // setTimeout(() => $(this).find('.text').removeClass('invisible'), 400);
        }
    });

    $(document).on('click', function() {
        $('.button-catalog').removeClass('active');
    });
}